var Swipe = require('./swipe.js');

function r(fn) {
  var done = false, top = true,

      doc               = window.document,
      root              = doc.documentElement,
      modern            = doc.addEventListener,

      add               = modern ? "addEventListener" : "attachEvent",
      rem               = modern ? "removeEventListener" : "detachEvent",
      pre               = modern ? "" : "on",

      init              = function (e) {
        if (e.type == "readystatechange" && doc.readyState != "complete") return;
        (e.type == "load" ? window : doc)[rem](pre + e.type, init, false);
        if (!done && (done = true)) fn.call(window, e.type || e);
      },

      poll              = function () {
        try {
          root.doScroll("left");
        } catch (e) {
          setTimeout(poll, 50);
          return;
        }
        init("poll");
      };

  if (doc.readyState == "complete") fn.call(window, "lazy");
  else {
    if (!modern && root.doScroll) {
      try {
        top = !window.frameElement;
      } catch (e) {
      }
      if (top) poll();
    }
    doc[add](pre + "DOMContentLoaded", init, false);
    doc[add](pre + "readystatechange", init, false);
    window[add](pre + "load", init, false);
  }
}

r(function(){
  /*console.log("ready");*/
  window.homeSlider = Swipe(document.getElementById('home-slider'), {
    auto: 7000,
    speed: 1000
  });

  window.designerSlider = Swipe(document.getElementById('designer-slider'));


  window.aboutSlider = Swipe(document.getElementById('about-slider'), {
    auto: 7000,
    speed: 1000
  });

  window.awardsSlider = Swipe(document.getElementById('awards-slider'), {
    auto: 7000,
    speed: 1000
  });

  window.referenceSlider = Swipe(document.getElementById('reference-slider'), {
    auto: 7000,
    speed: 1000
  });

  window.drawingSlider = Swipe(document.getElementById('drawing-slider'));
});

//category detail img
var acc = document.getElementsByClassName("accordion");

for (var i = 0; i < acc.length; i++) {

  acc[i].onclick = function () {
    console.log(this);
    console.log(this.nextElementSibling);

    if(this.classList.contains('active') === true) {
      this.classList.remove('active');
      this.nextElementSibling.classList.remove('show');
    } else {
      this.classList.add('active');
      this.nextElementSibling.classList.add('show');
    }
  }
}

//header-toggle
function toggleFunction() {
  var navbar = document.getElementById('siteNavbar');
  if (navbar.classList) {
    navbar.classList.toggle('responsive');
  } else {
    var classes = navbar.className.split(' ');
    var existingIndex = classes.indexOf('responsive');

    if (existingIndex >= 0) {
      classes.splice(existingIndex, 1);
    } else {
      classes.push('responsive');
    }
    navbar.className = classes.join(' ');
  }
}

//detail thumbnail
var thumbs = document.getElementsByClassName('thumbnail-photo');
var stage = document.getElementsByClassName('stage-photo')[0];

function thumbClick(item) {

  item.onclick = function () {
    stage.src = item.src;
    item.classList.add('active');
  };
}

for (var i = 0; i < thumbs.length; i++) {
  var item = thumbs[i];
  thumbClick(item);
}

// Language change
function languageChange(elem) {
  var url = window.location.href;
  var currentLang = elem.dataset.current;
  var nextLang = elem.dataset.lang;

  elem.addEventListener("click", function () {
    var newUrl;
    if (url.indexOf("/" + currentLang + "/") > -1) {
      newUrl = url.replace("/" + currentLang + "/", "/" + nextLang + "/");
      window.location.href = newUrl;
    } else {
      newUrl = "/" + nextLang + "/";
      window.location.href = newUrl;
    }
  });
}
var langItems = document.getElementsByClassName('lang-item');
for (var li = 0; li < langItems.length; li++) {
  languageChange(langItems[li]);
}

//product-image
var productImages = document.querySelectorAll(".product-list-element");
if (productImages.length) {
  var imageWidth = productImages[0].offsetWidth;

  for (var i = 0; i < productImages.length; i++) {
    productImages[i].style.height = imageWidth + 'px';
  }
}

var general = document.querySelectorAll('.general');
if (general.length) {
  for (var i = 0; i < general.length; i++) {
    general[i].style.height = '330px';
  }
}

// //window scrollevent
// var leftMenu = document.getElementsByClassName('left-menu');
// var menuHeight = leftMenu[0].offsetHeight;
//
// function loadFunction() {
//
//   var body = document.getElementsByTagName('body');
//
//   if (body[0].offsetWidth < 768) {
//     window.scrollTo(0, menuHeight);
//   }
// }

/*
window.onload = loadFunction();*/
